const presets = [
    [{"name":"hair-back","optionOn":false,"which":13,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":"29","saturation":"31","lightness":"39"},{"name":"body","optionOn":false,"which":5,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"40","saturation":"70","lightness":"40"},{"name":"eyes","optionOn":false,"which":8,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"brows","optionOn":false,"which":7,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"29","saturation":"31","lightness":"39"},{"name":"mouth","optionOn":false,"which":9,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"40","saturation":"70","lightness":"100"},{"name":"nose","optionOn":false,"which":7,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":"40","saturation":"70","lightness":"40"},{"name":"ears","optionOn":false,"which":7,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"40","saturation":"70","lightness":"40"},{"name":"extras","optionOn":false,"which":6,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":8,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":"29","saturation":"31","lightness":"39"},{"name":"clothes","optionOn":false,"which":5,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":"216","saturation":"40","lightness":"55"}],
    [{"name":"hair-back","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":19,"saturation":47,"lightness":77},{"name":"body","optionOn":false,"which":0,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":28,"saturation":"84","lightness":"85"},{"name":"eyes","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"205","saturation":"100","lightness":"63"},{"name":"brows","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":19,"saturation":47,"lightness":77},{"name":"mouth","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":28,"saturation":"84","lightness":"85"},{"name":"nose","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":28,"saturation":"84","lightness":"85"},{"name":"ears","optionOn":false,"which":0,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":28,"saturation":"84","lightness":"85"},{"name":"extras","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":true,"hue":"64","saturation":"76","lightness":"70"},{"name":"hair-front","optionOn":false,"which":0,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":19,"saturation":47,"lightness":77},{"name":"clothes","optionOn":false,"which":0,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":94,"saturation":38,"lightness":53}],
    [{"name":"hair-back","optionOn":false,"which":3,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":2,"saturation":100,"lightness":62},{"name":"body","optionOn":false,"which":4,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"332","saturation":"100","lightness":"95"},{"name":"eyes","optionOn":false,"which":15,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"288","saturation":"74","lightness":"80"},{"name":"brows","optionOn":false,"which":6,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":2,"saturation":100,"lightness":62},{"name":"mouth","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"332","saturation":"100","lightness":"95"},{"name":"nose","optionOn":false,"which":2,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":"0","saturation":"100","lightness":"52"},{"name":"ears","optionOn":false,"which":6,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"332","saturation":"100","lightness":"95"},{"name":"extras","optionOn":false,"which":5,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":9,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":2,"saturation":100,"lightness":62},{"name":"clothes","optionOn":false,"which":5,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":"206","saturation":"42","lightness":"52"}],
    [{"name":"hair-back","optionOn":false,"which":8,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":30,"saturation":44,"lightness":60},{"name":"body","optionOn":false,"which":7,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"359","saturation":"10","lightness":"50"},{"name":"eyes","optionOn":false,"which":21,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":344,"saturation":26,"lightness":85},{"name":"brows","optionOn":false,"which":15,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":30,"saturation":44,"lightness":60},{"name":"mouth","optionOn":false,"which":18,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"359","saturation":"36","lightness":"50"},{"name":"nose","optionOn":false,"which":8,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":"359","saturation":"10","lightness":"50"},{"name":"ears","optionOn":false,"which":15,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"359","saturation":"10","lightness":"50"},{"name":"extras","optionOn":false,"which":19,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":true,"hue":46,"saturation":"76","lightness":84},{"name":"hair-front","optionOn":false,"which":16,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":30,"saturation":44,"lightness":60},{"name":"clothes","optionOn":false,"which":6,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":232,"saturation":17,"lightness":72}],
    [{"name":"hair-back","optionOn":false,"which":12,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":10,"saturation":51,"lightness":93},{"name":"body","optionOn":false,"which":8,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":354,"saturation":"21","lightness":"49"},{"name":"eyes","optionOn":false,"which":28,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"247","saturation":"86","lightness":"77"},{"name":"brows","optionOn":false,"which":15,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":10,"saturation":51,"lightness":93},{"name":"mouth","optionOn":false,"which":17,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"274","saturation":"59","lightness":"64"},{"name":"nose","optionOn":false,"which":6,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":354,"saturation":"21","lightness":"49"},{"name":"ears","optionOn":false,"which":16,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":354,"saturation":"21","lightness":"49"},{"name":"extras","optionOn":false,"which":2,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":13,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":10,"saturation":51,"lightness":93},{"name":"clothes","optionOn":false,"which":11,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":13,"saturation":100,"lightness":50}],
    [{"name":"hair-back","optionOn":false,"which":3,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":228,"saturation":41,"lightness":80},{"name":"body","optionOn":false,"which":3,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"57","saturation":"19","lightness":"46"},{"name":"eyes","optionOn":false,"which":3,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"brows","optionOn":false,"which":3,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":228,"saturation":41,"lightness":80},{"name":"mouth","optionOn":false,"which":3,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":164,"saturation":63,"lightness":76},{"name":"nose","optionOn":false,"which":3,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":"57","saturation":"19","lightness":"46"},{"name":"ears","optionOn":false,"which":3,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"57","saturation":"19","lightness":"46"},{"name":"extras","optionOn":false,"which":"11","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":true,"hue":238,"saturation":83,"lightness":77},{"name":"hair-front","optionOn":false,"which":3,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":228,"saturation":41,"lightness":80},{"name":"clothes","optionOn":false,"which":3,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":85,"saturation":"43","lightness":"63"}],
    [{"name":"hair-back","optionOn":false,"which":16,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":354,"saturation":67,"lightness":58},{"name":"body","optionOn":false,"which":9,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"113","saturation":"45","lightness":"49"},{"name":"eyes","optionOn":false,"which":31,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"17","saturation":"100","lightness":"71"},{"name":"brows","optionOn":false,"which":17,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":354,"saturation":67,"lightness":58},{"name":"mouth","optionOn":false,"which":22,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"54","saturation":"64","lightness":"49"},{"name":"nose","optionOn":false,"which":20,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":"113","saturation":"45","lightness":"49"},{"name":"ears","optionOn":false,"which":17,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"113","saturation":"45","lightness":"49"},{"name":"extras","optionOn":false,"which":21,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":19,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":354,"saturation":67,"lightness":58},{"name":"clothes","optionOn":false,"which":12,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":336,"saturation":43,"lightness":20}],
    [{"name":"hair-back","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":26,"saturation":29,"lightness":52},{"name":"body","optionOn":false,"which":6,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":15,"saturation":16,"lightness":50},{"name":"eyes","optionOn":false,"which":11,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"38","saturation":"60","lightness":"51"},{"name":"brows","optionOn":false,"which":"3","top":-10,"left":5,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":26,"saturation":29,"lightness":52},{"name":"mouth","optionOn":false,"which":11,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"23","saturation":"34","lightness":"46"},{"name":"nose","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":15,"saturation":"27","lightness":"45"},{"name":"ears","optionOn":false,"which":9,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":15,"saturation":16,"lightness":50},{"name":"extras","optionOn":false,"which":15,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":true,"hue":"72","saturation":"100","lightness":"72"},{"name":"hair-front","optionOn":false,"which":6,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":26,"saturation":29,"lightness":52},{"name":"clothes","optionOn":false,"which":8,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":"271","saturation":"73","lightness":"72"}],
    [{"name":"hair-back","optionOn":false,"which":4,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":296,"saturation":87,"lightness":71},{"name":"body","optionOn":false,"which":"1","top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"315","saturation":"100","lightness":"56"},{"name":"eyes","optionOn":false,"which":"4","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"brows","optionOn":false,"which":0,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":296,"saturation":87,"lightness":71},{"name":"mouth","optionOn":false,"which":"20","top":5,"left":15,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"315","saturation":"100","lightness":"56"},{"name":"nose","optionOn":false,"which":17,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":"315","saturation":"100","lightness":"56"},{"name":"ears","optionOn":false,"which":3,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"315","saturation":"100","lightness":"56"},{"name":"extras","optionOn":false,"which":"20","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":17,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":296,"saturation":87,"lightness":71},{"name":"clothes","optionOn":false,"which":"10","top":-18,"left":-35,"rotation":0,"scaleWidth":540,"scaleHeight":540,"max":13,"disable":false,"color":true,"hue":"91","saturation":"54","lightness":73}],
    [{"name":"hair-back","optionOn":false,"which":"10","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":93,"saturation":12,"lightness":25},{"name":"body","optionOn":false,"which":4,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":332,"saturation":"27","lightness":"43"},{"name":"eyes","optionOn":false,"which":"12","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"181","saturation":"100","lightness":"50"},{"name":"brows","optionOn":false,"which":"12","top":0,"left":30,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":93,"saturation":12,"lightness":25},{"name":"mouth","optionOn":false,"which":"7","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":332,"saturation":"27","lightness":"100"},{"name":"nose","optionOn":false,"which":4,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":332,"saturation":"27","lightness":"43"},{"name":"ears","optionOn":false,"which":4,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":332,"saturation":"27","lightness":"43"},{"name":"extras","optionOn":false,"which":3,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":4,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":93,"saturation":12,"lightness":25},{"name":"clothes","optionOn":false,"which":"7","top":-18,"left":-35,"rotation":0,"scaleWidth":540,"scaleHeight":540,"max":13,"disable":false,"color":true,"hue":"149","saturation":"77","lightness":"46"}],
    [{"name":"hair-back","optionOn":false,"which":1,"top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":268,"saturation":89,"lightness":67},{"name":"body","optionOn":false,"which":1,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"86","saturation":"31","lightness":"46"},{"name":"eyes","optionOn":false,"which":"7","top":0,"left":5,"rotation":-0.1,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"54","saturation":"100","lightness":"50"},{"name":"brows","optionOn":false,"which":"11","top":0,"left":-5,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":268,"saturation":89,"lightness":67},{"name":"mouth","optionOn":false,"which":"14","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":true,"hue":"167","saturation":"50","lightness":"56"},{"name":"nose","optionOn":false,"which":"4","top":5,"left":-10,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":false,"color":true,"hue":"86","saturation":"31","lightness":"46"},{"name":"ears","optionOn":false,"which":1,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"86","saturation":"31","lightness":"46"},{"name":"extras","optionOn":false,"which":"14","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":1,"top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":false,"color":true,"hue":268,"saturation":89,"lightness":67},{"name":"clothes","optionOn":false,"which":"6","top":2,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":false,"color":true,"hue":"31","saturation":"36","lightness":"50"}],
    [{"name":"hair-back","optionOn":false,"which":"13","top":15,"left":45,"rotation":-0.9999999999999999,"scaleWidth":500,"scaleHeight":500,"max":17,"disable":false,"color":true,"hue":"162","saturation":"48","lightness":"51"},{"name":"body","optionOn":false,"which":"7","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":10,"disable":false,"color":true,"hue":"259","saturation":"23","lightness":"50"},{"name":"eyes","optionOn":false,"which":"19","top":-5,"left":25,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":32,"disable":false,"color":true,"hue":"315","saturation":"100","lightness":"50"},{"name":"brows","optionOn":false,"which":"17","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":true,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"mouth","optionOn":false,"which":"13","top":5,"left":-15,"rotation":-0.2,"scaleWidth":500,"scaleHeight":500,"max":23,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":50},{"name":"nose","optionOn":false,"which":"18","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":21,"disable":true,"color":true,"hue":"259","saturation":"23","lightness":"50"},{"name":"ears","optionOn":false,"which":"6","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":18,"disable":false,"color":true,"hue":"259","saturation":"23","lightness":"50"},{"name":"extras","optionOn":false,"which":"11","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":22,"disable":false,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"hair-front","optionOn":false,"which":"4","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":20,"disable":true,"color":false,"hue":0,"saturation":0,"lightness":0},{"name":"clothes","optionOn":false,"which":"11","top":0,"left":0,"rotation":0,"scaleWidth":500,"scaleHeight":500,"max":13,"disable":true,"color":false,"hue":0,"saturation":0,"lightness":0}],
]

export default presets